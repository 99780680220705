import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { LoadingService } from '../services/loading.service';
import { SessionService } from '../services/session.service';

@Injectable()
export class HttpInterceptorModule implements HttpInterceptor {
  constructor(
    private readonly sessionService: SessionService,
    private readonly loadingService: LoadingService,
    private readonly toastService: ToastrService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const id = this.loadingService.show();

    const token = this.sessionService.getToken();

    if (token) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }

    return next.handle(req).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse && event.status === 201) {
          // this.toastr.success('Object created.');
          console.log('teste', event);
        }
      }),
      map((event: HttpEvent<any>) => {
        return event;
      }),
      finalize(() => {
        this.loadingService.hide(id);
      })
    );
  }
}
