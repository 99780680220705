import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionService } from '../../services/session.service';

@Component({
  selector: 'app-handle-auth',
  templateUrl: './handle-auth.component.html',
  styleUrls: ['./handle-auth.component.scss'],
})
export class HandleAuthComponent implements OnInit {
  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly sessionService: SessionService
  ) {}

  ngOnInit() {
    const token = this.activatedRoute.snapshot.paramMap.get('token');

    if (token) {
      this.sessionService.setToken(token);
      this.router.navigateByUrl('/instituicoes-financeiras');
    }
  }
}
