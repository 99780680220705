import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit {
  @Input() itensPorPagina = 100;
  @Input() paginaSelecionada = 1;
  @Input() totalDeItens = 1000;
  @Input() totalPaginas = 1;

  @Output() trocouPaginaSelecionada = new EventEmitter<number>();
  @Output() acaoNovo = new EventEmitter();


  constructor() {}

  ngOnInit() {

  }
}
