import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IAnotacaoInstituicaoFinanceira } from 'src/app/modules/instituicoes-financeiras/interfaces/anotacoes.interface';
import { environment } from 'src/environments/environment';
import { IOperationResponse } from '../../models/operation-response.interface';

@Injectable({
  providedIn: 'root',
})
export class ApiAnotacoesService {
  private apiUrl = `${environment.baseUrl}${environment.api.financeiro}Instituicao`;

  constructor(private readonly httpClient: HttpClient) {}

  carregarTodasAnotacoes(
    InstituicaoId: number
  ): Observable<IOperationResponse<IAnotacaoInstituicaoFinanceira[]>> {
    return this.httpClient.get<
      IOperationResponse<IAnotacaoInstituicaoFinanceira[]>
    >(`${this.apiUrl}/${InstituicaoId}/comentario`);
  }

  salvarAnotacao(
    InstituicaoId: number,
    dados: IAnotacaoInstituicaoFinanceira
  ): Observable<IOperationResponse<IAnotacaoInstituicaoFinanceira[]>> {
    return this.httpClient.post<
      IOperationResponse<IAnotacaoInstituicaoFinanceira[]>
    >(`${this.apiUrl}/${InstituicaoId}/comentario`, dados);
  }

  editarAnotacao(
    InstituicaoId: number,
    dados: IAnotacaoInstituicaoFinanceira
  ): Observable<IOperationResponse<IAnotacaoInstituicaoFinanceira[]>> {
    return this.httpClient.put<
      IOperationResponse<IAnotacaoInstituicaoFinanceira[]>
    >(`${this.apiUrl}/${InstituicaoId}/comentario`, dados);
  }

  excluirAnotacao(
    InstituicaoId: number,
    AnotacaoId: number
  ): Observable<IOperationResponse<IAnotacaoInstituicaoFinanceira[]>> {
    return this.httpClient.delete<
      IOperationResponse<IAnotacaoInstituicaoFinanceira[]>
    >(`${this.apiUrl}/${InstituicaoId}/comentario/${AnotacaoId}`);
  }
}
