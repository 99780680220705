/* eslint-disable @angular-eslint/directive-selector */
import { Directive, TemplateRef, ViewContainerRef, Input } from '@angular/core';
import { SessionService } from '../../core/services/session.service';

@Directive({
  selector: '[isAllowed]'
})
export class IsAllowedDirective {
  private requiredPermission!: string | string[];
  private userPermissions!: string[];

  // example usage <component *isAllowed="'olivee.employees.doctorList'"></component>

  constructor(
    private readonly templateRef: TemplateRef<any>,
    private readonly viewContainer: ViewContainerRef,
    private readonly sessionService: SessionService
  ) {
    this.listenUser();
  }

  @Input()
  set isAllowed(value: string | string[]) {
    this.requiredPermission = value;
  }

  private listenUser(): void {
    // captura sessão do usuário > Pegar pegar a lista de permissões do usuário > Chama updateView()
    // this.userPermissions = this.sessionService.getSession().userdata;
    // simulação de assincronismo
    setTimeout(() => {
      this.updateView();
    }, 500);
  }

  private updateView(): void {
    this.viewContainer.clear();

    if (this.checkUserHasPermission()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }

  private checkUserHasPermission(): boolean {
    // if (this.userPermissions) {
    //   if (this.requiredPermission instanceof Array) {
    //     let permit = false;

    //     this.requiredPermission.forEach((permission) => {
    //       if (
    //         this.userPermissions.find((elem) => elem === permission || permission === this.sessionService.getSession().role)
    //       ) {
    //         permit = true;
    //       }
    //     });
    //     return permit;
    //   } else {
    //     return this.userPermissions.find(
    //       (elem) => elem === this.requiredPermission || elem === this.sessionService.getSession().role
    //     )
    //       ? true
    //       : false;
    //   }
    // }
    return false;
  }
}
