import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InstituicaoFinanceiraCadastroComponent } from './pages/instituicao-financeira-cadastro/instituicao-financeira-cadastro.component';
import { InstituicaoFinanceiraComponent } from './pages/instituicao-financeira/instituicao-financeira.component';

const routes: Routes = [
  { path: '', component: InstituicaoFinanceiraComponent },
  { path: 'cadastro', component: InstituicaoFinanceiraCadastroComponent },
  { path: ':id/editar', component: InstituicaoFinanceiraCadastroComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class InstituicoesFinanceirasRoutingModule {}
