<div class="pagination">
  <div class="pagination__actions">
    <button class="primary" (click)="acaoNovo.emit()">Novo</button>
  </div>
  <div class="pagination__tamanho">
    <p>Itens por página: {{ itensPorPagina }}</p>
  </div>
  <div class="pagination__dados">
    <p>
      {{ paginaSelecionada * itensPorPagina + 1 }} -
      {{ itensPorPagina * (paginaSelecionada + 1) }}
    </p>
  </div>
  <div class="pagination__actions-paginacao">
    <button
      mat-icon-button
      class="secondary"
      (click)="trocouPaginaSelecionada.emit(paginaSelecionada - 1)"
      [disabled]="paginaSelecionada === 0"
    >
      <mat-icon> chevron_left </mat-icon>
    </button>
    <button
      mat-icon-button
      class="secondary"
      (click)="trocouPaginaSelecionada.emit(paginaSelecionada + 1)"
      [disabled]="paginaSelecionada + 1 === totalPaginas"
    >
      <mat-icon> chevron_right </mat-icon>
    </button>
  </div>
</div>
