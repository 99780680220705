import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HandleAuthComponent } from './core/auth/handle-auth/handle-auth.component';
import { IsAuthGuard } from './core/guards/is-auth.guard';
import { InstituicoesFinanceirasModule } from './modules/instituicoes-financeiras/instituicoes-financeiras.module';

const routes: Routes = [
  {
    path: 'instituicoes-financeiras',
    loadChildren: () => InstituicoesFinanceirasModule,
    canActivate: [IsAuthGuard],
  },
  {
    path: 'auth/:token',
    component: HandleAuthComponent,
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'instituicoes-financeiras'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
