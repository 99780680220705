import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  IFilial,
  IFiltrosListagemInstituicaoFinanceira,
  IInstituicaoFinanceira,
  IListagemInstituicaoFinanceira,
} from 'src/app/modules/instituicoes-financeiras/interfaces/instituicao-financeira.interface';
import { IOperationResponse } from '../../models/operation-response.interface';

@Injectable({
  providedIn: 'root',
})
export class ApiInstituicoesFinanceirasService {
  private apiUrl = `${environment.baseUrl}${environment.api.financeiro}Instituicao`;

  constructor(private httpClient: HttpClient) {}

  listarInstituicoesFinanceiras(
    filtros: IFiltrosListagemInstituicaoFinanceira
  ): Observable<IOperationResponse<IListagemInstituicaoFinanceira[]>> {
    let params = new HttpParams();

    if (filtros.razaoSocial) {
      params = params.append('razaoSocial', filtros.razaoSocial);
    }

    if (filtros.fantasia) {
      params = params.append('fantasia', filtros.fantasia);
    }

    if (filtros.cnpj) {
      params = params.append('cnpj', filtros.cnpj);
    }

    if (filtros.codigoBanco) {
      params = params.append('codigoBanco', filtros.codigoBanco);
    }

    if (filtros.active !== undefined && filtros.active !== null) {
      params = params.append('active', filtros.active);
    }

    params = params.append('size', filtros.size);
    params = params.append('index', filtros.index);

    return this.httpClient.get<
      IOperationResponse<IListagemInstituicaoFinanceira[]>
    >(`${this.apiUrl}/search`, { params });
  }

  detalhesInstituicaoFinanceira(
    id: number
  ): Observable<IOperationResponse<IInstituicaoFinanceira>> {
    return this.httpClient.get<IOperationResponse<IInstituicaoFinanceira>>(
      `${this.apiUrl}/${id}`
    );
  }

  criarInstituicaoFinanceira(
    dadosInstituicaoFinanceira: IInstituicaoFinanceira
  ): Observable<IOperationResponse<IInstituicaoFinanceira>> {
    return this.httpClient.post<IOperationResponse<IInstituicaoFinanceira>>(
      `${this.apiUrl}`,
      dadosInstituicaoFinanceira
    );
  }

  editarInstituicaoFinanceira(
    dadosInstituicaoFinanceira: IInstituicaoFinanceira
  ): Observable<IOperationResponse<IInstituicaoFinanceira>> {
    return this.httpClient.put<IOperationResponse<IInstituicaoFinanceira>>(
      `${this.apiUrl}/${dadosInstituicaoFinanceira.InstituicaoId}`,
      dadosInstituicaoFinanceira
    );
  }

  statusInstituicaoFinanceira(
    id: number,
    activo: boolean
  ): Observable<IOperationResponse<IInstituicaoFinanceira>> {
    return this.httpClient.post<IOperationResponse<IInstituicaoFinanceira>>(
      `${this.apiUrl}/${id}/status?activo=${activo}`,
      {}
    );
  }

  buscarFilialPorCnpj(cnpj: string): Observable<IOperationResponse<IFilial>> {
    return this.httpClient.get<IOperationResponse<IFilial>>(
      `${this.apiUrl}/dados-filial/${cnpj}`
    );

    // Mock aguardando API
    // return new Observable((obs) => {
    //   obs.next({
    //     InstituicaoId: 2,
    //     RazaoSocial: 'Testes',
    //     Fantasia: 'Testes Fantasia',
    //     TipoRelacao: 1,
    //     TipoRelacaoDsc: 'Concorrente',
    //     Cnpj: '03960306000188',
    //     Activo: true,
    //     CnpjLojaFilial: '12345678901234',
    //     LojaFilial: 'Loja Filial Teste SP',
    //     CodigoLojaSap: '123',
    //   } as any);
    // });
  }
}
