import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss'],
})
export class ActionsComponent implements OnInit {
  @Input() exibeAcaoInativar = false;
  @Input() descricaoBotaoStatus = "Inativar";
  @Output() acaoSalvar = new EventEmitter();
  @Output() acaoVoltar = new EventEmitter();
  @Output() acaoAtivarInativar = new EventEmitter();

  constructor() {}

  ngOnInit() {}
}
