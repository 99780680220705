import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ITiposRelacao } from '../../models/tipos-relacao-interface';
import { IPersonalidadeJuridica } from '../../models/personalidade-juridica.interface';
import { environment } from 'src/environments/environment';
import { IOperationResponse } from '../../models/operation-response.interface';

@Injectable({
  providedIn: 'root',
})
export class ApiDadosAuxiliaresService {
  private apiUrl = `${environment.baseUrl}${environment.api.financeiro}Instituicao`;
  constructor(private readonly httpClient: HttpClient) {}

  public carregarTiposRelacao(): Observable<
    IOperationResponse<ITiposRelacao[]>
  > {
    return this.httpClient.get<IOperationResponse<ITiposRelacao[]>>(
      `${this.apiUrl}/tipo-relacao`
    );
  }

  public carregarPersonalidadeJuridica(): Observable<
    IOperationResponse<IPersonalidadeJuridica[]>
  > {
    return this.httpClient.get<IOperationResponse<IPersonalidadeJuridica[]>>(
      `${this.apiUrl}/personalidade-juridica`
    );
  }
}
