import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LocalStorage } from '../helpers/local-storage.decorator';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  @LocalStorage('SESSION') private session!: any | null;
  private readonly _session$ = new BehaviorSubject<any | null>(this.session);
  public session$ = this._session$.asObservable();

  @LocalStorage('TOKEN') private token!: string;
  private readonly _token$ = new BehaviorSubject(this.token);
  public token$ = this._token$.asObservable();

  constructor() {}

  getToken(): string {
    return this.token;
  }

  setToken(token: string): void {
    this._token$.next(token);
    this.token = token;
  }

  resetSession(): void {
    this._session$.next(null);
    this.session = null;
    this._token$.next('');
    this.token = '';
  }
}
