import { ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { LoadingService } from './core/services/loading.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  isLoading: Subject<boolean> = this.loadingService.isLoading;

  constructor(
    private readonly loadingService: LoadingService,
    private readonly cdr: ChangeDetectorRef,
    private readonly router: Router
  ) {
    this.router.events.subscribe(() => {
      this.cdr.detectChanges();
    });
  }
}
