import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { EMPTY, Observable } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { IOperationResponse } from 'src/app/core/models/operation-response.interface';
import { IPersonalidadeJuridica } from 'src/app/core/models/personalidade-juridica.interface';
import { ITiposRelacao } from 'src/app/core/models/tipos-relacao-interface';
import { ApiAnotacoesService } from 'src/app/core/services/apis/api-anotacoes.service';
import { ApiDadosAuxiliaresService } from 'src/app/core/services/apis/api-dados-auxiliares.service';
import { ApiInstituicoesFinanceirasService } from 'src/app/core/services/apis/api-instituicoes-financeiras.service';
import { ApiViacepService } from 'src/app/core/services/apis/api-viacep.service';
import { SessionService } from 'src/app/core/services/session.service';
import { IAnotacaoInstituicaoFinanceira } from '../../interfaces/anotacoes.interface';
import {
  IFilial,
  IInstituicaoFinanceira,
} from '../../interfaces/instituicao-financeira.interface';

@Component({
  selector: 'app-instituicao-financeira-cadastro',
  templateUrl: './instituicao-financeira-cadastro.component.html',
  styleUrls: ['./instituicao-financeira-cadastro.component.scss'],
})
export class InstituicaoFinanceiraCadastroComponent implements OnInit {
  formCadastroInstituicaoFinanceira = new FormGroup({
    RazaoSocial: new FormControl('', [Validators.required]),
    Fantasia: new FormControl('', [Validators.required]),
    TipoRelacao: new FormControl('', [Validators.required]),
    PersonalidadeJuridica: new FormControl('', [Validators.required]),
    Cnpj: new FormControl('', [Validators.required]),
    InscricaoEstadual: new FormControl(''),
    InscricaoMunicipal: new FormControl(''),
    CodigoBanco: new FormControl('', [
      Validators.required,
      Validators.maxLength(8),
    ]),
    Email: new FormControl('', [
      Validators.required,
      Validators.email,
      Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
    ]),
    Telefone: new FormControl('', [Validators.required]),
    // celular: new FormControl('', [Validators.required]),
    // site: new FormControl('', [Validators.required]),
    // whatsapp: new FormControl('', [Validators.required]),
    Cep: new FormControl('', [
      Validators.required,
      Validators.minLength(8),
      Validators.maxLength(8),
    ]),
    TipoLogradouro: new FormControl('', [Validators.required]),
    Logradouro: new FormControl('', [Validators.required]),
    Numero: new FormControl('', [Validators.required]),
    Complemento: new FormControl(''),
    UF: new FormControl('', [Validators.required]),
    Cidade: new FormControl('', [Validators.required]),
    Bairro: new FormControl('', [Validators.required]),
    Pais: new FormControl('', [Validators.required]),
    ForcarEndereco: new FormControl(false),
    Activo: new FormControl(true),
    DataCriacao: new FormControl(null),
    DataAlteracao: new FormControl(null),
  });

  formAnotacoes = new FormGroup({
    Comentario: new FormControl('', [Validators.required]),
  });

  formFaturamentoSap = new FormGroup({
    CnpjLojaFilial: new FormControl('', [
      Validators.required,
      Validators.minLength(14),
      Validators.maxLength(14),
    ]),
    LojaFilial: new FormControl({ value: '', disabled: true }, [
      Validators.required,
    ]),
    CodigoLojaSap: new FormControl('', [Validators.required]),
  });

  tipoTela: 'editar' | 'adicionar' = 'adicionar';
  instituicaoFinanceiraId?: number | null;

  opcoesTipoRelacao: ITiposRelacao[] = [];
  opcoesPersonalidadesJuridica: IPersonalidadeJuridica[] = [];
  carregandoDados = true;

  opcoesUF = [
    'AC',
    'AL',
    'AM',
    'AP',
    'BA',
    'CE',
    'DF',
    'ES',
    'GO',
    'MA',
    'MG',
    'MS',
    'MT',
    'PA',
    'PB',
    'PE',
    'PI',
    'PR',
    'RJ',
    'RN',
    'RO',
    'RR',
    'RS',
    'SC',
    'SE',
    'SP',
    'TO',
  ];

  anotacoes: IAnotacaoInstituicaoFinanceira[] = [];
  anotacaoId?: number;

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly apiDadosAuxiliaresService: ApiDadosAuxiliaresService,
    private readonly apiInstitucoesFinanceiras: ApiInstituicoesFinanceirasService,
    private readonly apiViacep: ApiViacepService,
    private readonly apiAnotacoes: ApiAnotacoesService,
    private readonly toastService: ToastrService
  ) {}

  ngOnInit() {
    this.carregarDadosTela();

    this.instituicaoFinanceiraId = Number(
      this.activatedRoute.snapshot.paramMap.get('id')
    );

    if (this.instituicaoFinanceiraId) {
      this.tipoTela = 'editar';
    }

    this.formCadastroInstituicaoFinanceira
      .get('Cep')
      ?.valueChanges.subscribe((cep) => {
        if (cep.length === 8) {
          this.carregarDadosCep(cep);
        }
      });
  }

  public carregarDadosTela(): void {
    this.carregarTiposRelacao()
      .pipe(
        switchMap((tiposRelacao) => {
          this.opcoesTipoRelacao = tiposRelacao.Data;

          const relacaoInstituicaoFinanceira = this.opcoesTipoRelacao.find(
            (opcao) => opcao.Value === 'Instituição Financeira'
          );

          if (relacaoInstituicaoFinanceira) {
            this.formCadastroInstituicaoFinanceira
              .get('TipoRelacao')
              ?.patchValue(relacaoInstituicaoFinanceira.Id);
          } else {
            this.toastService.error(
              `Não foi possível encontrar o tipo de relação "Instituição Financeira" no banco de dados. Por favor contate o administrador do sistema`,
              ``,
              {
                disableTimeOut: true,
              }
            );
          }

          return this.carregarPersonalidadeJuridica();
        }),
        switchMap((personalidadesJuridica) => {
          this.opcoesPersonalidadesJuridica = personalidadesJuridica.Data;

          if (this.instituicaoFinanceiraId) {
            return this.carregarDadosInstituicaoFinanceira();
          }
          this.carregandoDados = false;
          return EMPTY;
        }),
        catchError((e) => {
          this.tratarError(e);

          return EMPTY;
        })
      )
      .subscribe((dadosInstituicaoFinanceira) => {
        this.carregandoDados = false;

        this.formCadastroInstituicaoFinanceira.patchValue(
          dadosInstituicaoFinanceira.Data
        );
        this.formFaturamentoSap.patchValue(
          dadosInstituicaoFinanceira.Data
        );
      });
  }

  public carregarTiposRelacao(): Observable<
    IOperationResponse<ITiposRelacao[]>
  > {
    return this.apiDadosAuxiliaresService.carregarTiposRelacao();
  }

  public carregarPersonalidadeJuridica(): Observable<
    IOperationResponse<IPersonalidadeJuridica[]>
  > {
    return this.apiDadosAuxiliaresService.carregarPersonalidadeJuridica();
  }

  public carregarDadosInstituicaoFinanceira(): Observable<
    IOperationResponse<IInstituicaoFinanceira>
  > {
    this.carregarAnotacoes(this.instituicaoFinanceiraId!);
    return this.apiInstitucoesFinanceiras.detalhesInstituicaoFinanceira(
      this.instituicaoFinanceiraId!
    );
  }

  public salvarInstituicaoFinanceira(): void {
    const lojaFilial = this.formFaturamentoSap.get('LojaFilial')
      ?.value as string;

    this.formCadastroInstituicaoFinanceira.markAllAsTouched();
    this.formFaturamentoSap.markAllAsTouched();

    if (
      this.formCadastroInstituicaoFinanceira.valid &&
      this.formFaturamentoSap.valid &&
      lojaFilial
    ) {
      if (this.instituicaoFinanceiraId) {
        const data = {
          ...this.formCadastroInstituicaoFinanceira.getRawValue(),
          ...this.formFaturamentoSap.getRawValue(),
          InstituicaoId: this.instituicaoFinanceiraId,
        };
        this.apiInstitucoesFinanceiras
          .editarInstituicaoFinanceira(data)
          .subscribe(
            (resposta) => {
              this.toastService.success(
                `Instituição Financeira salva com sucesso`
              );

              const anotacao = this.formAnotacoes.get('Comentario')?.value;

              if (anotacao) {
                this.criarAnotacao(anotacao, this.instituicaoFinanceiraId!);
              }

              this.voltar();
            },
            (e) => {
              this.toastService.error(
                `Ocorreu um erro inesperado. Por favor tente mais tarde.`,
                ``,
                { disableTimeOut: true }
              );

              if (e && e.error) {
                if (e.error.Data) {
                  e.error.Data.forEach((erro: string) => {
                    this.toastService.error(`${erro}`, ``, {
                      disableTimeOut: true,
                    });
                  });
                }

                if (e.error.Message) {
                  this.toastService.error(`${e.error.Message}`, ``, {
                    disableTimeOut: true,
                  });
                }
              }
            }
          );
      } else {
        this.formCadastroInstituicaoFinanceira
          .get('DataCriacao')!
          .patchValue(new Date().toISOString());
        this.formCadastroInstituicaoFinanceira
          .get('DataAlteracao')!
          .patchValue(new Date().toISOString());
        this.apiInstitucoesFinanceiras
          .criarInstituicaoFinanceira({
            ...this.formCadastroInstituicaoFinanceira.getRawValue(),
            ...this.formFaturamentoSap.getRawValue(),
          })
          .subscribe(
            (resposta) => {
              if (this.anotacoes.length > 0) {
                this.anotacoes.forEach((anotacao) => {
                  this.criarAnotacao(
                    anotacao.Comentario,
                    resposta.Data.InstituicaoId
                  );
                });

                const anotacao = this.formAnotacoes.get('Comentario')?.value;

                if (anotacao) {
                  this.criarAnotacao(anotacao, resposta.Data.InstituicaoId);
                }
              }

              this.toastService.success(
                `Instituição Financeira salva com sucesso`
              );

              this.voltar();
            },
            (e) => {
              this.toastService.error(
                `Ocorreu um erro inesperado. Por favor tente mais tarde.`,
                ``,
                { disableTimeOut: true }
              );

              if (e && e.error) {
                if (e.error.Data) {
                  e.error.Data.forEach((erro: string) => {
                    this.toastService.error(`${erro}`, ``, {
                      disableTimeOut: true,
                    });
                  });
                }

                if (e.error.Message) {
                  this.toastService.error(`${e.error.Message}`, ``, {
                    disableTimeOut: true,
                  });
                }
              }
            }
          );
      }
    } else {
      this.toastService.error(
        'O cadastro não pode ser salvo sem que todos os campos obrigatórios tenham sido preenchidos.'
      );
    }
  }

  public voltar(): void {
    this.router.navigateByUrl('/instituicoes-financeiras');
  }

  public ativarDesativarInstituicaoFinanceira(): void {
    this.apiInstitucoesFinanceiras
      .statusInstituicaoFinanceira(
        this.instituicaoFinanceiraId!,
        !this.formCadastroInstituicaoFinanceira.get('Activo')!.value
      )
      .subscribe(
        (resposta) => {
          this.toastService.success(
            !this.formCadastroInstituicaoFinanceira.get('Activo')!.value
              ? `Instituição Financeira ativada com sucesso`
              : `Instituição Financeira desativada com sucesso`
          );
          this.carregarDadosTela();
        },
        (e) => {
          this.toastService.error(
            `Ocorreu um erro inesperado. Por favor tente mais tarde.`,
            ``,
            { disableTimeOut: true }
          );

          if (e && e.error) {
            if (e.error.Data) {
              e.error.Data.forEach((erro: string) => {
                this.toastService.error(`${erro}`, ``, {
                  disableTimeOut: true,
                });
              });
            }

            if (e.error.Message) {
              this.toastService.error(`${e.error.Message}`, ``, {
                disableTimeOut: true,
              });
            }
          }
        }
      );
  }

  public formatarValorTipoRelacao(tipoRelacao: ITiposRelacao): number {
    return tipoRelacao.Id;
  }

  public formatarDescricaoTipoRelacao(tipoRelacao: ITiposRelacao): string {
    return tipoRelacao.Value;
  }

  public formatarUF(uf: string): string {
    return uf;
  }

  public carregarDadosCep(cep: string): void {
    if (!this.formCadastroInstituicaoFinanceira.get('ForcarEndereco')!.value) {
      this.apiViacep.pegarEnderecoPeloCEP(cep).subscribe(
        (dadosEndereco) => {
          if (dadosEndereco?.erro === 'true') {
            return;
          }

          const arrayLogradouro = dadosEndereco?.logradouro?.split(' ');
          this.formCadastroInstituicaoFinanceira
            .get('TipoLogradouro')!
            .patchValue(arrayLogradouro[0]);

          arrayLogradouro.shift();

          this.formCadastroInstituicaoFinanceira
            .get('Logradouro')!
            .patchValue(arrayLogradouro.join(' '));
          this.formCadastroInstituicaoFinanceira
            .get('UF')!
            .patchValue(dadosEndereco.uf);
          this.formCadastroInstituicaoFinanceira
            .get('Cidade')!
            .patchValue(dadosEndereco.localidade);
          this.formCadastroInstituicaoFinanceira
            .get('Bairro')!
            .patchValue(dadosEndereco.bairro);
          this.formCadastroInstituicaoFinanceira.get('Pais')!.patchValue('BR');

          this.formCadastroInstituicaoFinanceira
            .get('TipoLogradouro')!
            .disable();
          this.formCadastroInstituicaoFinanceira.get('Logradouro')!.disable();
          this.formCadastroInstituicaoFinanceira.get('UF')!.disable();
          this.formCadastroInstituicaoFinanceira.get('Cidade')!.disable();
          this.formCadastroInstituicaoFinanceira.get('Bairro')!.disable();
          this.formCadastroInstituicaoFinanceira.get('Pais')!.disable();
        },
        (e) => {
          this.toastService.error(
            `Ocorreu um erro inesperado. Por favor tente mais tarde.`,
            ``,
            { disableTimeOut: true }
          );

          if (e && e.error) {
            if (e.error.Data) {
              e.error.Data.forEach((erro: string) => {
                this.toastService.error(`${erro}`, ``, {
                  disableTimeOut: true,
                });
              });
            }

            if (e.error.Message) {
              this.toastService.error(`${e.error.Message}`, ``, {
                disableTimeOut: true,
              });
            }
          }
        }
      );
    }
  }

  mudouValorCheckbox(status: boolean): void {
    this.formCadastroInstituicaoFinanceira
      .get('ForcarEndereco')!
      .patchValue(status);

    if (status) {
      this.formCadastroInstituicaoFinanceira.get('TipoLogradouro')!.enable();
      this.formCadastroInstituicaoFinanceira.get('Logradouro')!.enable();
      this.formCadastroInstituicaoFinanceira.get('UF')!.enable();
      this.formCadastroInstituicaoFinanceira.get('Cidade')!.enable();
      this.formCadastroInstituicaoFinanceira.get('Bairro')!.enable();
      this.formCadastroInstituicaoFinanceira.get('Pais')!.enable();
    } else {
      const cep = this.formCadastroInstituicaoFinanceira.get('Cep')?.value;

      if (cep && cep.length === 8) {
        this.carregarDadosCep(cep);
      }
    }
  }

  criarAnotacao(anotacao: string, instituicaoFinanceiraId: number): void {
    this.apiAnotacoes
      .salvarAnotacao(instituicaoFinanceiraId, {
        Comentario: anotacao,
        InstituicaoId: instituicaoFinanceiraId,
      })
      .subscribe(() => {});
  }

  salvarAnotacao(): void {
    if (this.formAnotacoes.valid) {
      if (this.tipoTela === 'editar') {
        if (!this.anotacaoId) {
          this.apiAnotacoes
            .salvarAnotacao(this.instituicaoFinanceiraId!, {
              ...this.formAnotacoes.getRawValue(),
              InstituicaoId: this.instituicaoFinanceiraId!,
            })
            .subscribe(
              (anotacoes) => {
                this.carregarAnotacoes(this.instituicaoFinanceiraId!);
                this.toastService.success(`Anotação salva com sucesso`);
                this.resetDados();
              },
              (e) => {
                this.toastService.error(
                  `Ocorreu um erro inesperado. Por favor tente mais tarde.`,
                  ``,
                  { disableTimeOut: true }
                );

                if (e && e.error) {
                  if (e.error.Data) {
                    e.error.Data.forEach((erro: string) => {
                      this.toastService.error(`${erro}`, ``, {
                        disableTimeOut: true,
                      });
                    });
                  }

                  if (e.error.Message) {
                    this.toastService.error(`${e.error.Message}`, ``, {
                      disableTimeOut: true,
                    });
                  }
                }
              }
            );
        } else {
          this.apiAnotacoes
            .editarAnotacao(this.instituicaoFinanceiraId!, {
              ...this.formAnotacoes.getRawValue(),
              InstituicaoId: this.instituicaoFinanceiraId!,
              Id: this.anotacaoId,
              DataAlteracao: new Date().toISOString(),
              DataCriacao: this.anotacoes.find(
                (anotacao) => anotacao.Id === this.anotacaoId
              )!.DataCriacao,
            })
            .subscribe(
              (anotacoes) => {
                this.carregarAnotacoes(this.instituicaoFinanceiraId!);
                this.toastService.success(`Anotação salva com sucesso`);
                this.resetDados();
              },
              (e) => {
                this.toastService.error(
                  `Ocorreu um erro inesperado. Por favor tente mais tarde.`,
                  ``,
                  { disableTimeOut: true }
                );

                if (e && e.error) {
                  if (e.error.Data) {
                    e.error.Data.forEach((erro: string) => {
                      this.toastService.error(`${erro}`, ``, {
                        disableTimeOut: true,
                      });
                    });
                  }

                  if (e.error.Message) {
                    this.toastService.error(`${e.error.Message}`, ``, {
                      disableTimeOut: true,
                    });
                  }
                }
              }
            );
        }
      } else {
        this.anotacoes.push({
          Comentario: this.formAnotacoes.get('Comentario')!.value,
          DataCriacao: new Date().toISOString(),
          DataAlteracao: new Date().toISOString(),
        });

        this.resetDados();
      }
    } else {
      this.toastService.error(`Não é possível criar uma anotação vazia.`);
    }
  }

  resetDados(): void {
    this.formAnotacoes.reset();
    this.anotacaoId = undefined;
  }

  carregarAnotacoes(instituicaoId: number): void {
    this.apiAnotacoes.carregarTodasAnotacoes(instituicaoId).subscribe(
      (anotacoes) => {
        this.anotacoes = anotacoes.Data;
      },
      (e) => {
        this.toastService.error(
          `Ocorreu um erro inesperado. Por favor tente mais tarde.`,
          ``,
          { disableTimeOut: true }
        );

        if (e && e.error) {
          if (e.error.Data) {
            e.error.Data.forEach((erro: string) => {
              this.toastService.error(`${erro}`, ``, {
                disableTimeOut: true,
              });
            });
          }

          if (e.error.Message) {
            this.toastService.error(`${e.error.Message}`, ``, {
              disableTimeOut: true,
            });
          }
        }
      }
    );
  }

  excluirAnotacao(anotacaoId: number, index: number): void {
    if (this.tipoTela === 'adicionar') {
      this.anotacoes.splice(index, 1);
    } else {
      this.apiAnotacoes
        .excluirAnotacao(this.instituicaoFinanceiraId!, anotacaoId)
        .subscribe(
          (anotacoes) => {
            this.carregarAnotacoes(this.instituicaoFinanceiraId!);
            this.toastService.success(`Anotação excluída com sucesso`);
            this.resetDados();
          },
          (e) => {
            this.toastService.error(
              `Ocorreu um erro inesperado. Por favor tente mais tarde.`,
              ``,
              { disableTimeOut: true }
            );

            if (e && e.error) {
              if (e.error.Data) {
                e.error.Data.forEach((erro: string) => {
                  this.toastService.error(`${erro}`, ``, {
                    disableTimeOut: true,
                  });
                });
              }

              if (e.error.Message) {
                this.toastService.error(`${e.error.Message}`, ``, {
                  disableTimeOut: true,
                });
              }
            }
          }
        );
    }
  }

  editarAnotacao(anotacao: IAnotacaoInstituicaoFinanceira): void {
    this.formAnotacoes.patchValue({
      Comentario: anotacao.Comentario,
    });
    this.anotacaoId = anotacao.Id;
  }

  tratarError(e: any): void {
    this.toastService.error(
      `Ocorreu um erro ao carregar os dados da tela. Por favor tente mais tarde.`,
      ``,
      { disableTimeOut: true }
    );

    if (e && e.error) {
      if (e.error.Data) {
        e.error.Data.forEach((erro: string) => {
          this.toastService.error(`${erro}`, ``, {
            disableTimeOut: true,
          });
        });
      }

      if (e.error.Message) {
        this.toastService.error(`${e.error.Message}`, ``, {
          disableTimeOut: true,
        });
      }
    }
  }

  getDate(dataCriacao: string): string {
    const d = new Date(dataCriacao);
    d.setHours(d.getHours() - 3);
    return d.toISOString();
  }

  buscarFilial(): void {
    const input = this.formFaturamentoSap.get('CnpjLojaFilial')?.value;

    if (input.length === 14) {
      // if (input === '11111111111112') {
      //   this.toastService.error('Nenhuma filial encontrada com este CNPJ', ``, {
      //     disableTimeOut: true,
      //   });

      //   this.formFaturamentoSap.patchValue({
      //     LojaFilial: '',
      //     CodigoLojaSap: '',
      //   });

      //   return;
      // }

      this.apiInstitucoesFinanceiras.buscarFilialPorCnpj(input).subscribe({
        next: (filial) => {
          if (filial) {
            this.formFaturamentoSap.patchValue({
              LojaFilial: filial?.Data?.LojaFilial || '',
              CodigoLojaSap: filial?.Data?.CodigoLojaSap || '',
            });
          }
        },
        error: (e) => {
          this.formFaturamentoSap.patchValue({
            LojaFilial: '',
            CodigoLojaSap: '',
          });

          if (e && e.error) {
            if (e.error.Data) {
              e.error.Data.forEach((erro: string) => {
                this.toastService.error(`${erro}`, ``, {
                  disableTimeOut: true,
                });
              });

              return;
            }

            if (e.error.Message) {
              this.toastService.error(`${e.error.Message}`, ``, {
                disableTimeOut: true,
              });

              return;
            }

            this.toastService.error(
              `Ocorreu um erro inesperado. Por favor tente mais tarde.`,
              ``,
              { disableTimeOut: true }
            );

            return;
          }
        },
      });
    }
  }
}
