import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  ids = new Set();

  isLoading = new Subject<boolean>();

  constructor() {}

  private generateId(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      // tslint:disable-next-line: no-bitwise
      const r = (Math.random() * 16) | 0;
      // tslint:disable-next-line: no-bitwise
      const v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  public show(): string {
    const id = this.generateId();
    if (!this.ids.has(id)) {
      this.ids.add(id);
      this.isLoading.next(true);
      return id;
    }
    return id;
  }

  public hide(id: string): void {
    if (this.ids.has(id)) {
      this.ids.delete(id);
      if (this.ids.size === 0) {
        this.isLoading.next(false);
      }
    }
  }
}
