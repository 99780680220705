import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IDadosEndereco } from '../../models/dados-endereco.interface';

@Injectable({
  providedIn: 'root',
})
export class ApiViacepService {
  constructor(private httpClient: HttpClient) {}

  public pegarEnderecoPeloCEP(cep: string): Observable<IDadosEndereco> {
    return this.httpClient.get<IDadosEndereco>(
      `https://viacep.com.br/ws/${cep}/json/`
    );
  }
}
