import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent implements OnInit, OnChanges {
  values!: Observable<any[]>;
  selectedValue: any;
  dataFilter: any[] = [];
  filteredData: any[] = [];
  oldValue: any;
  isLoading = false;
  hasSubscribed = false;

  // Obrigatório
  @Input() form!: FormGroup;
  @Input() controlName!: string;
  @Input() customOptions: any = undefined;
  @Input() formatValue: any = (value: any) => value;

  // Opcionais
  @Input() formatDescricao: any = (value: any) => value;
  @Input() desativado = false;
  @Input() multiplo = false;
  @Input() obrigatorio = false;
  // 'legacy' | 'standard' | 'fill' | 'outline'
  @Input() appearance = 'standard';
  @Input() loading = false;
  @Input() label = '';
  @Input() placeholder = '';
  @Input() inputIconPosition = 'matSuffix';
  @Input() inputIcon = '';
  @Input() labelError = '';
  @Input() required: any = undefined;

  @Output() optionSelected: any = new EventEmitter();
  @Output() blur: any = new EventEmitter();

  ngOnInit(): void {
    if (this.formatDescricao === '(value) => value') {
      // TODO retirar depois de integrar
      this.formatDescricao = this.formatValue;
    }
    if (this.loading) {
      this.form.get(this.controlName)!.disable();
    }
  }

  ngOnChanges(): void {
    if (!this.loading) {
      this.form.get(this.controlName)!.enable();
    }

    if (this.desativado) {
      this.form.get(this.controlName)!.disable();
    }
  }
}
