<div class="input-texto">
  <div class="input">
    <mat-form-field [appearance]="appearance">
      <mat-label *ngIf="label">
        {{ label }}
      </mat-label>

      <input
        #inputField
        type="text"
        matInput
        [formControl]="form.get(controlName)"
        [placeholder]="placeholder"
        (input)="handleInput(inputField.value)"
        [mask]="mask"
      />

      <mat-icon
        class="input-icon"
        matPrefix
        *ngIf="inputIconPosition === 'matPrefix'"
      >
        {{ inputIcon }}
      </mat-icon>
      <mat-icon
        class="input-icon"
        matSuffix
        *ngIf="inputIconPosition === 'matSuffix'"
      >
        {{ inputIcon }}
      </mat-icon>

      <mat-error
      *ngIf="
        form?.get(controlName)!.errors &&
        form?.get(controlName)!.touched
      "
    >
      <ng-container *ngIf="form?.get(nomeControle)?.errors?.minlength">
        O campo precisa ter no mínimo
        {{ form?.get(nomeControle)?.errors?.minlength.requiredLength }}
        caracteres e tem
        {{ form?.get(nomeControle)?.errors?.minlength.actualLength }}
        caracteres.
      </ng-container>

      <ng-container *ngIf="form?.get(nomeControle)?.errors?.maxlength">
        O campo precisa ter no máximo
        {{ form?.get(nomeControle)?.errors?.maxlength.requiredLength }}
        caracteres e tem
        {{ form?.get(nomeControle)?.errors?.maxlength.actualLength }}
        caracteres.
      </ng-container>

      <ng-container *ngIf="form?.get(nomeControle)?.errors?.required">
        O campo é obrigatório.
      </ng-container>

      <ng-container *ngIf="form?.get(nomeControle)?.errors?.email">
        O campo não é um e-mail válido.
      </ng-container>
    </mat-error>
    </mat-form-field>
  </div>
</div>

<ng-content *ngIf="hasIcon"></ng-content>
