import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { InstituicoesFinanceirasRoutingModule } from './instituicoes-financeiras-routing.module';
import { InstituicaoFinanceiraComponent } from './pages/instituicao-financeira/instituicao-financeira.component';
import { InstituicaoFinanceiraCadastroComponent } from './pages/instituicao-financeira-cadastro/instituicao-financeira-cadastro.component';
import { InstituicaoFinanceiraFiltroComponent } from './components/instituicao-financeira-filtro/instituicao-financeira-filtro.component';
import { AngularMaterialModule } from 'src/app/shared/angular-material.module';
import { NgxMaskModule } from 'ngx-mask';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    InstituicaoFinanceiraComponent,
    InstituicaoFinanceiraCadastroComponent,
    InstituicaoFinanceiraFiltroComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    InstituicoesFinanceirasRoutingModule,
    AngularMaterialModule,
    NgxMaskModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [],
  providers: [],
})
export class InstituicoesFinanceirasModule {}
