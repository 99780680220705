import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent {
  // Obrigatório
  @Input() form!: FormGroup;
  @Input() controlName!: string;

  // Opcionais
  @Input() obrigatorio = false;
  // 'legacy' | 'standard' | 'fill' | 'outline'
  @Input() appearance = 'standard';
  @Input() label = '';
  @Input() placeholder = '';
  @Input() inputIconPosition = 'matSuffix';
  @Input() inputIcon = '';
  @Input() labelError = '';
  @Input() required: any = undefined;
  @Input() mask = '';
  @Input() hasIcon = false;

  @Output() optionSelected: any = new EventEmitter();
  @Output() blur: any = new EventEmitter();
  @Output() inputEvent = new EventEmitter();

  public handleInput(input: string) {
    this.inputEvent.emit(input);
  }
}
