import { Component, NgZone, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiInstituicoesFinanceirasService } from 'src/app/core/services/apis/api-instituicoes-financeiras.service';
import { IListagemInstituicaoFinanceira } from '../../interfaces/instituicao-financeira.interface';

@Component({
  selector: 'app-instituicao-financeira',
  templateUrl: './instituicao-financeira.component.html',
  styleUrls: ['./instituicao-financeira.component.scss'],
})
export class InstituicaoFinanceiraComponent implements OnInit {
  Math = Math;

  instituicoesFinanceiras: IListagemInstituicaoFinanceira[] = [];

  paginaSelecionada = 0;
  totalItens = 0;
  itensPorPagina = 100;

  filtroForm = new FormGroup({
    razaoSocial: new FormControl(''),
    nomeFantasia: new FormControl(''),
    cnpj: new FormControl(''),
    codBanco: new FormControl(''),
    ativo: new FormControl(true),
  });

  filtroEstaAberto = false;

  constructor(
    private readonly router: Router,
    private readonly apiInstitucoesFinanceiras: ApiInstituicoesFinanceirasService,
    private readonly toastService: ToastrService
  ) {}

  ngOnInit() {
    this.listarInstitucoesFinanceiras();
  }

  listarInstitucoesFinanceiras() {
    this.filtroEstaAberto = false;

    this.apiInstitucoesFinanceiras
      .listarInstituicoesFinanceiras({
        razaoSocial: this.filtroForm.get('razaoSocial')!.value,
        fantasia: this.filtroForm.get('nomeFantasia')!.value,
        cnpj: this.filtroForm.get('cnpj')!.value,
        codigoBanco: this.filtroForm.get('codBanco')!.value,
        active: this.filtroForm.get('ativo')!.value,
        size: this.itensPorPagina,
        index: this.paginaSelecionada,
      })
      .subscribe(
        (resposta) => {
          this.instituicoesFinanceiras = resposta.Data;
          this.totalItens = resposta.Count;
        },
        (e) => {
          this.toastService.error(
            `Ocorreu um erro inesperado. Por favor tente mais tarde.`,
            ``,
            { disableTimeOut: true }
          );

          if (e && e.error) {
            if (e.error.Data) {
              e.error.Data.forEach((erro: string) => {
                this.toastService.error(`${erro}`, ``, {
                  disableTimeOut: true,
                });
              });
            }

            if (e.error.Message) {
              this.toastService.error(`${e.error.Message}`, ``, {
                disableTimeOut: true,
              });
            }
          }
        }
      );
  }

  trocouPaginaSelecionada(paginaSelecionada: number): void {
    this.paginaSelecionada = paginaSelecionada;
    this.listarInstitucoesFinanceiras();
  }

  criarNovaInstituicaoFinanceira(): void {
    this.router.navigateByUrl('/instituicoes-financeiras/cadastro');
  }

  limparFiltros(): void {
    this.filtroForm.reset();
    this.listarInstitucoesFinanceiras();
  }

  verDetalhes(id: number): void {
    this.router.navigateByUrl(`/instituicoes-financeiras/${id}/editar`);
  }
}
