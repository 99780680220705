<div class="instituicao-financeira-filtro" [class.open]="open">
  <div class="instituicao-financeira-filtro__header">
    <h2>Filtros</h2>
    <button mat-icon-button (click)="mudarStatusFiltro.emit(false)">
      <mat-icon> close </mat-icon>
    </button>
  </div>

  <div class="instituicao-financeira-filtro__form" [formGroup]="filtroForm">
    <div class="instituicao-financeira-filtro__campo">
      <mat-form-field appearance="standard">
        <mat-label>CNPJ</mat-label>
        <input
          matInput
          formControlName="cnpj"
          placeholder="00.000.000/0000-00"
          mask="00.000.000/0000-00"
        />
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
    </div>
    <div class="instituicao-financeira-filtro__campo">
      <mat-form-field appearance="standard">
        <mat-label>Cod. Banco</mat-label>
        <input
          matInput
          formControlName="codBanco"
          placeholder="99999999"
          mask="99999999"
        />
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
    </div>
    <div class="instituicao-financeira-filtro__campo">
      <mat-form-field appearance="standard">
        <mat-label>Razão Social</mat-label>
        <input
          matInput
          formControlName="razaoSocial"
          placeholder="Razão Social"
        />
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
    </div>
    <div class="instituicao-financeira-filtro__campo">
      <mat-form-field appearance="standard">
        <mat-label>Nome Fantasia</mat-label>
        <input
          matInput
          formControlName="nomeFantasia"
          placeholder="Nome Fantasia"
        />
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
    </div>
    <div class="instituicao-financeira-filtro__campo">
      <mat-form-field appearance="standard">
        <mat-label>Ativo</mat-label>
        <mat-select formControlName="ativo">
          <mat-option *ngFor="let status of statuses" [value]="status.valor">
            {{ status.desc }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="instituicao-financeira-filtro__actions">
    <button class="secondary" (click)="limparFiltros.emit()">
      Limpar Filtros
    </button>
    <button class="secondary" (click)="filtrar.emit()">Buscar</button>
  </div>
</div>
