import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-instituicao-financeira-filtro',
  templateUrl: './instituicao-financeira-filtro.component.html',
  styleUrls: ['./instituicao-financeira-filtro.component.scss'],
})
export class InstituicaoFinanceiraFiltroComponent implements OnInit {
  @Input() open = false;
  @Input() filtroForm!: FormGroup;

  @Output() limparFiltros = new EventEmitter();
  @Output() filtrar = new EventEmitter();
  @Output() mudarStatusFiltro = new EventEmitter<boolean>();

  statuses = [
    {
      desc: 'Ativo',
      valor: true,
    },
    {
      desc: 'Inativo',
      valor: false,
    },
  ];

  constructor() {}

  ngOnInit() {}
}
