import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from './components/button/button.component';
import { InputComponent } from './components/input/input.component';
import { AuthService } from '../core/services/auth.service';
import { SessionService } from '../core/services/session.service';
import { LoadingService } from '../core/services/loading.service';
import { IsAllowedDirective } from './helpers/is-allowed.directive';
import { PaginationComponent } from './components/pagination/pagination.component';
import { AngularMaterialModule } from './angular-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { ActionsComponent } from './components/actions/actions.component';
import { SelectComponent } from './components/select/select.component';
import { LoadingComponent } from './components/loading/loading.component';

const components = [
  ButtonComponent,
  InputComponent,
  PaginationComponent,
  BreadcrumbsComponent,
  ActionsComponent,
  SelectComponent,
  LoadingComponent,
];

@NgModule({
  declarations: [...components, IsAllowedDirective],
  imports: [
    CommonModule,
    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule,
  ],
  exports: [...components, IsAllowedDirective],
  providers: [AuthService, SessionService, LoadingService],
})
export class SharedModule {}
