<div class="instituicao-financeira">
  <div class="instituicao-financeira__topo">
    <app-breadcrumbs
      title="Instituições Financeiras"
      path="Home / Conta / Instituições Financeiras"
    >
    </app-breadcrumbs>
    <div class="instituicao-financeira__busca">
      <!-- <mat-form-field appearance="standard">
        <input matInput placeholder="Procurar por Nome ou CNPJ" />
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field> -->
    </div>
    <div class="instituicao-financeira__filtrar">
      <button mat-icon-button (click)="filtroEstaAberto = true">
        <mat-icon> filter_list </mat-icon>
      </button>
    </div>
  </div>
  <div class="instituicao-financeira__body">
    <div class="instituicao-financeira__listagem">
      <table>
        <thead>
          <tr>
            <th>Nome Fantasia</th>
            <th>Razão Social</th>
            <th>CNPJ</th>
            <th>Tipo Relação</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngIf="instituicoesFinanceiras.length > 0; else tabelaVazia">
            <ng-container
              *ngFor="let instituicaoFinanceira of instituicoesFinanceiras"
            >
              <tr (click)="verDetalhes(instituicaoFinanceira.InstituicaoId)">
                <td>
                  {{ instituicaoFinanceira.Fantasia }}
                </td>
                <td>
                  {{ instituicaoFinanceira.RazaoSocial }}
                </td>
                <td>
                  {{ instituicaoFinanceira.Cnpj | mask: "00.000.000/0000-00" }}
                </td>
                <td>
                  {{ instituicaoFinanceira.TipoRelacaoDsc }}
                </td>
              </tr>
            </ng-container>
          </ng-container>
          <ng-template #tabelaVazia>
            <tr>
              <td colspan="4">Não há itens a serem exibidos.</td>
            </tr>
          </ng-template>
        </tbody>
      </table>
    </div>
  </div>
  <div class="instituicao-financeira__paginacao">
    <app-pagination
      [itensPorPagina]="itensPorPagina"
      [paginaSelecionada]="paginaSelecionada"
      [totalDeItens]="totalItens"
      [totalPaginas]="Math.ceil(totalItens / itensPorPagina)"
      (trocouPaginaSelecionada)="trocouPaginaSelecionada($event)"
      (acaoNovo)="criarNovaInstituicaoFinanceira()"
    ></app-pagination>
  </div>
</div>

<app-instituicao-financeira-filtro
  [open]="filtroEstaAberto"
  [filtroForm]="filtroForm"
  (limparFiltros)="limparFiltros()"
  (filtrar)="listarInstitucoesFinanceiras()"
  (mudarStatusFiltro)="filtroEstaAberto = $event"
></app-instituicao-financeira-filtro>
