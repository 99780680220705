<div class="input-select">
  <div class="input">
    <mat-form-field [appearance]="appearance">
      <mat-label *ngIf="label">
        {{ label }}
      </mat-label>
      <mat-select
        [formControl]="form.get(controlName)"
        [required]="obrigatorio"
        [multiple]="multiplo"
        [placeholder]="placeholder"
      >
        <mat-option
          *ngFor="let option of customOptions"
          [value]="formatValue(option)"
        >
          {{ formatDescricao(option) }}
        </mat-option>
      </mat-select>

      <mat-icon
        class="input-icon"
        matPrefix
        *ngIf="inputIconPosition === 'matPrefix'"
      >
        {{ inputIcon }}
      </mat-icon>
      <mat-icon
        class="input-icon"
        matSuffix
        *ngIf="inputIconPosition === 'matSuffix'"
      >
        {{ inputIcon }}
      </mat-icon>

      <mat-error *ngIf="form.get(controlName)!.invalid">
        Este campo é obrigatório.
      </mat-error>
    </mat-form-field>
  </div>

  <ng-container *ngIf="loading">
    <mat-spinner diameter="25"> </mat-spinner>
  </ng-container>
</div>
