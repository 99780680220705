<div class="cadastro-if">
  <div class="cadastro-if__header">
    <app-breadcrumbs
      title="Instituições Financeiras"
      path="Home / Conta / Instituições Financeiras"
    >
    </app-breadcrumbs>
  </div>

  <div
    class="cadastro-if__container"
    [formGroup]="formCadastroInstituicaoFinanceira"
  >
    <div class="cadastro-if__container-wrapper">
      <div class="cadastro-if__container-title">
        Conta<span *ngIf="tipoTela === 'editar'"
          >:
          {{
            formCadastroInstituicaoFinanceira.get("RazaoSocial")?.value
          }}</span
        >
      </div>
      <div class="cadastro-if__container-conta">
        <app-input
          controlName="RazaoSocial"
          label="Razão Social"
          placeholder="Razão Social"
          [form]="formCadastroInstituicaoFinanceira"
        ></app-input>
        <app-input
          controlName="Fantasia"
          label="Fantasia"
          placeholder="Fantasia"
          [form]="formCadastroInstituicaoFinanceira"
        ></app-input>
        <app-select
          controlName="TipoRelacao"
          label="Tipo de Relação"
          placeholder="Tipo de Relação"
          [form]="formCadastroInstituicaoFinanceira"
          [customOptions]="opcoesTipoRelacao"
          [formatValue]="formatarValorTipoRelacao"
          [formatDescricao]="formatarDescricaoTipoRelacao"
          [loading]="carregandoDados"
        >
        </app-select>
        <app-select
          controlName="PersonalidadeJuridica"
          label="Personalidade Jurídica"
          placeholder="Personalidade Jurídica"
          [form]="formCadastroInstituicaoFinanceira"
          [customOptions]="opcoesPersonalidadesJuridica"
          [formatValue]="formatarValorTipoRelacao"
          [formatDescricao]="formatarDescricaoTipoRelacao"
          [loading]="carregandoDados"
        >
        </app-select>
      </div>
    </div>

    <div class="cadastro-if__container-wrapper">
      <div class="cadastro-if__tab">
        <mat-tab-group mat-stretch-tabs class="stretched-tabs">
          <mat-tab label="Geral">
            <div class="cadastro-if__container-title">Dados do Banco</div>
            <div class="cadastro-if__container-banco">
              <app-input
                controlName="Cnpj"
                label="CNPJ"
                placeholder="CNPJ"
                mask="00.000.000/0000-00"
                [form]="formCadastroInstituicaoFinanceira"
              ></app-input>
              <app-input
                controlName="InscricaoEstadual"
                label="Inscrição Estadual"
                placeholder="Inscrição Estadual"
                [form]="formCadastroInstituicaoFinanceira"
              ></app-input>
              <app-input
                controlName="InscricaoMunicipal"
                label="Inscrição Municipal"
                placeholder="Inscrição Municipal"
                [form]="formCadastroInstituicaoFinanceira"
              ></app-input>
              <app-input
                controlName="CodigoBanco"
                label="Código do Banco"
                placeholder="Código do Banco"
                [form]="formCadastroInstituicaoFinanceira"
                mask="99999999"
              ></app-input>
              <!-- <app-select
          controlName="CodigoBanco"
          label="Código do Banco"
          placeholder="Código do Banco"
          [form]="formCadastroInstituicaoFinanceira"
          [customOptions]="opcoesTipoRelacao"
          [formatValue]="formatarValorTipoRelacao"
          [formatDescricao]="formatarDescricaoTipoRelacao"
        >
        </app-select> -->
            </div>
          </mat-tab>
          <mat-tab label="Anotações">
            <div class="cadastro-if__container-title">Anotações</div>
            <div class="cadastro-if__container-anotacoes">
              <app-input
                controlName="Comentario"
                label="Observação"
                placeholder="Observação"
                [form]="formAnotacoes"
              ></app-input>
            </div>
            <div
              class="cadastro-if__container-anotacoes"
              style="justify-content: flex-end"
            >
              <button
                mat-icon-button
                (click)="resetDados()"
                style="margin-right: 0.5rem"
              >
                <mat-icon> close </mat-icon>
              </button>
              <button mat-icon-button (click)="salvarAnotacao()">
                <mat-icon> save </mat-icon>
              </button>
            </div>
            <div class="cadastro-if__container-anotacoes">
              <table>
                <thead>
                  <tr>
                    <th style="width: 20%">Data Usuário</th>
                    <th style="width: 45%">Observação</th>
                    <th style="width: 30%">Arquivo</th>
                    <th style="width: 5%"></th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngIf="anotacoes.length > 0; else tabelaVazia">
                    <ng-container
                      *ngFor="
                        let anotacao of anotacoes;
                        let indexAnotacao = index
                      "
                    >
                      <tr>
                        <td>
                          {{
                            getDate(anotacao.DataCriacao)
                              | date: "dd/MM/YYYY HH:mm:ss"
                          }}
                        </td>
                        <td>
                          {{ anotacao.Comentario }}
                        </td>
                        <td></td>
                        <td>
                          <div style="display: flex">
                            <button
                              *ngIf="tipoTela !== 'adicionar'"
                              mat-icon-button
                              (click)="editarAnotacao(anotacao)"
                              style="margin-right: 1rem"
                            >
                              <mat-icon> edit </mat-icon>
                            </button>
                            <button
                              mat-icon-button
                              (click)="
                                excluirAnotacao(anotacao.Id, indexAnotacao)
                              "
                            >
                              <mat-icon> delete </mat-icon>
                            </button>
                          </div>
                        </td>
                      </tr>
                    </ng-container>
                  </ng-container>
                  <ng-template #tabelaVazia>
                    <tr>
                      <td colspan="4">Não há itens a serem exibidos.</td>
                    </tr>
                  </ng-template>
                </tbody>
              </table>
            </div>
          </mat-tab>

          <mat-tab label="Faturamento (SAP)">
            <div class="cadastro-if__container-title">Faturamento (SAP)</div>
            <div class="cadastro-if__container-faturamento-sap">
              <app-input
                class="cadastro-if__container-faturamento-sap-input"
                controlName="CnpjLojaFilial"
                label="CNPJ Loja Fifial"
                placeholder="CNPJ"
                mask="00.000.000/0000-00"
                [form]="formFaturamentoSap"
                [hasIcon]="true"
              >
                <mat-icon
                  class="cadastro-if__container-faturamento-sap-icon"
                  (click)="buscarFilial()"
                >
                  search
                </mat-icon>
              </app-input>
              <app-input
                controlName="LojaFilial"
                label="Loja Filial"
                placeholder="Loja Filial"
                [form]="formFaturamentoSap"
              ></app-input>
              <app-input
                controlName="CodigoLojaSap"
                label="Código da Loja SAP"
                placeholder="Código da Loja SAP"
                [form]="formFaturamentoSap"
              ></app-input>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>

    <div class="cadastro-if__container-wrapper">
      <div class="cadastro-if__container-title">Contato e Email</div>
      <div class="cadastro-if__container-contato">
        <app-input
          controlName="Email"
          label="Email"
          placeholder="Email"
          [form]="formCadastroInstituicaoFinanceira"
        ></app-input>
        <app-input
          controlName="Telefone"
          label="Telefone"
          placeholder="Telefone"
          mask="(00) 0000-0000"
          [form]="formCadastroInstituicaoFinanceira"
        ></app-input>
        <!-- <app-input
          controlName="celular"
          label="Celular"
          placeholder="Celular"
          mask="(00) 0 0000-0000"
          [form]="formCadastroInstituicaoFinanceira"
        ></app-input>
        <app-input
          controlName="site"
          label="Site"
          placeholder="Site"
          [form]="formCadastroInstituicaoFinanceira"
        ></app-input>
        <app-input
          controlName="whatsapp"
          label="Telefone Whastapp"
          mask="(00) 0 0000-0000||(00) 0000-0000"
          placeholder="Telefone Whastapp"
          [form]="formCadastroInstituicaoFinanceira"
        ></app-input> -->
      </div>
    </div>

    <div class="cadastro-if__container-wrapper">
      <div class="cadastro-if__container-title">Endereço</div>
      <div class="cadastro-if__container-endereco">
        <div class="cadastro-if__container-input">
          <label for="forcarEndereco">Forçar Endereço</label>
          <mat-checkbox
            formControlName="ForcarEndereco"
            color="primary"
            (change)="mudouValorCheckbox($event.checked)"
          >
          </mat-checkbox>
        </div>
        <app-input
          controlName="Cep"
          label="CEP"
          placeholder="CEP"
          mask="00000-000"
          [form]="formCadastroInstituicaoFinanceira"
        ></app-input>
        <app-input
          controlName="TipoLogradouro"
          label="Tipo Logradouro"
          placeholder="Tipo Logradouro"
          [form]="formCadastroInstituicaoFinanceira"
        ></app-input>
        <app-input
          controlName="Logradouro"
          label="Logradouro"
          placeholder="Logradouro"
          [form]="formCadastroInstituicaoFinanceira"
        ></app-input>
        <app-input
          controlName="Numero"
          label="Número"
          placeholder="Número"
          [form]="formCadastroInstituicaoFinanceira"
        ></app-input>
        <app-input
          controlName="Complemento"
          label="Complemento"
          placeholder="Complemento"
          [form]="formCadastroInstituicaoFinanceira"
        ></app-input>
        <app-select
          controlName="UF"
          label="UF"
          placeholder="UF"
          [form]="formCadastroInstituicaoFinanceira"
          [customOptions]="opcoesUF"
          [formatValue]="formatarUF"
          [formatDescricao]="formatarUF"
        >
        </app-select>
        <app-input
          controlName="Cidade"
          label="Cidade"
          placeholder="Cidade"
          [form]="formCadastroInstituicaoFinanceira"
        ></app-input>
        <app-input
          controlName="Bairro"
          label="Bairro"
          placeholder="Bairro"
          [form]="formCadastroInstituicaoFinanceira"
        ></app-input>
        <app-input
          controlName="Pais"
          label="País"
          placeholder="País"
          [form]="formCadastroInstituicaoFinanceira"
        ></app-input>
      </div>
    </div>

    <br />
    <br />
  </div>
</div>

<app-actions
  [exibeAcaoInativar]="!!this.instituicaoFinanceiraId"
  [descricaoBotaoStatus]="
    formCadastroInstituicaoFinanceira.get('Activo')?.value
      ? 'Inativar'
      : 'Ativar'
  "
  (acaoSalvar)="salvarInstituicaoFinanceira()"
  (acaoVoltar)="voltar()"
  (acaoAtivarInativar)="ativarDesativarInstituicaoFinanceira()"
>
</app-actions>
